/* Base */
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

/* Common */
.card {
  border: 1px solid #6c6c6c;
  box-shadow: 3px 3px 2px #bdbebe;
  padding: 16px;
  background: #fefefe;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 480px;
  height: 480px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.modalExit {
  position: absolute;
  right: 8px;
  top: 4px;
  cursor: pointer;
}

.modalHeader {
  width: 100%;
  font-size: 24px;
  height: 28px;
}

.modalBody {
  width: 100%;
  height: 422px;
  padding: 4px;
  display: flex;
  justify-content: center;
}

.modalFooter {
  width: 100%;
  height: 32px;
}

.pagination {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 4px;
  gap: 8px;
  font-size: 24px;
}
.pagination button {
  background: none;
  border: 0;
  font-size: 24px;
}

/* Navigation */
.navigation {
  display: flex;
  width: 100%;
  gap: 0px;
}
.navigation a {
  text-decoration: none;
  padding: 12px 12px;
  color: #010101;
}
.navigation a:first-child {
  text-decoration: none;
  padding: 12px 12px;
  color: #31bacd;
}
.navigation a:hover {
  text-decoration: solid;
  color: #fc7b54;
}

/* home page */
.homepage {
  display: flex;
  width: 100%;
}
.homepage .header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 100%;
  height: calc(100vh - 44px);
}
.homepage h1 {
  text-align: center;
  flex: 1;
  font-size: 80px;
}
.homepage .codingImg {
  flex: 1;
  height: 100%;
  background-image: url(../public/homePage.jpg);
  background-size: cover;
  background-position: 50%;
}

/* Resume */
.resume {
  padding: 70px 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 756px;
  width: 715px;
  margin: 20px auto;
}
.resumeHeader {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
}
.resumeHeader h1 {
  font-size: 56px;
  margin: 0px;
  line-height: 50px;
}
.resumeHeader p {
  margin: 4px 0px;
  font-size: 14px;
}
.resumeLeft {
  width: 460px;
  margin-right: 36px; 
}
.resumeRight {
  width: 219px;
}
.resumeSectionHeader {
  font-size: 14px;
  margin-bottom: 4px;
}
.resumeExperiencePosition {
  padding: 4px 0px 12px;
}
.resumeExperienceTitle {
  font-size: 17px;
}
.resumeExperienceDate {
  margin: 8px 0;
  font-size: 11px;
}
.resumeExperienceDetails {
  font-size: 14px;
}
.resumeSkillPosition {
  font-size: 14px;
  padding: 12px 0;
}
.resumeAwardsPosition {
  font-size: 14px;
  margin: 8px 0;
}
.reachMe {
  font-size: 14px;
}

/* Personal */
.personalWrapper {
  padding: 4px 16px;
}
.flickrPhotosWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.flickrImgContainer {
  max-height: 525px;
  max-width: 325px;
  width: 19%;
  height: 20vw;
  display: flex;
  background-position: center;
  background-size: cover;
}
.flickrModalPhoto {
  max-height: 100%;
  max-width: 100%;
}

/* Colors */
.black {
  color: #010101;
}
.borBlack {
  border: 1px solid #010101;
}

.white {
  color: #fefefe;
}
.bgWhite {
  background-color: #fefefe;
}

.purple {
  color: #902bf5;
}
.bgPurple {
  background-color: #902bf5
}

.coral {
  color: #fc7b54;
}

.silver {
  color: #bdbebe;
}

.gray {
  color: #6c6c6c;
}
.borRightGray {
  border-right: 1px solid #6c6c6c;
}
.borBottomGray {
  border-bottom: 1px solid #6c6c6c;
}

.dark-purple {
  color: #1a1463;
}
.bgDark-purple {
  background-color: #1a1463;
}

.blue {
  color: #31bacd;
}

.pink {
  color: #f361fb;
}